import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import FormLayout from "../../components/layouts/FormLayout";
import { isEmpty } from "../../utils/proprietaryHooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TabComponent from "../../components/TabsComponent/TabComponent";
import {Grid, MenuItem} from "@material-ui/core";
import MonitoringView from "./monitoring.view";
import CustomForms from "../customForms/customForms.view";
import { MonitoringDashBoard } from "./monitoringDashboard";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";

//#region Entity's dependencies
import { getTransactionTypesByEnterprise } from "../../actions/objecttype.action";
//#endregion

/**
 * TracingForm Component ( full view for form to create/modify integration )
 *
 * @export Class Component
 * @extends {Component}
 * @returns Redux connect
 */
const TracingForm = (props) => {
  /* #region  Props */
  const {
    user,
    userPrivileges,
    setLoading,
    enterpriseRecord,
    getTransactionTypesByEnterprise,
    transactionTypesResponse
  } = props;

  const[objectTypeSelected] = useState(
    {id:"pifeventlog", value: "pifeventlog", name:"Log de eventos", selected:true, filter:""}
  );
  const[objectTypes, setObjectTypes] = useState([objectTypeSelected]);
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    objectType: Yup.string().required(requiredMessage),
  });
  const {
    control,
    watch,
  } = useForm({
    defaultValues: {
      objectType: "pifeventlog"
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });
  //#endregion

  /* #region  useEffect */
  useEffect(() => {
    if(enterpriseRecord.id)
      getTransactionTypesByEnterprise(enterpriseRecord.name);
  }, [getTransactionTypesByEnterprise, enterpriseRecord]);

  useEffect(() => {
    if (transactionTypesResponse && transactionTypesResponse.length) {
      const transactionTypes = [];
      transactionTypesResponse.sort((a,b)=>{return a.name===b.name?0:a.name<b.name?-1:1;}).forEach(transactionType => {
        const objtransactionType = jsonParse(transactionType.description);
        if(objtransactionType.id){
          transactionTypes.push({
            id: objtransactionType.id
            , value: objtransactionType.value
            , name: objtransactionType.name
            , selected: objtransactionType.selected
            , filter: objtransactionType.filter
          });
        }
      });
      setObjectTypes(transactionTypes);
    }
  }, [, transactionTypesResponse]);
  /* #endregion */

  const jsonParse = (value, initialValue = {}, callback)=>{
    let obj = initialValue;
    try{
      obj = JSON.parse(value);
    } catch (ex){
      if(callback)
        callback(ex);
    }
    return obj;
  }

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  const handleAccept = (e) => {
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const defineSubHeader = () => {
    let subheader = "";
    switch (tab) {
      case 0:
        subheader = "Panel de control";
        break;
      case 1:
        subheader = "Bitácora de errores";
        break;
      case 2:
        subheader = "Detalle de transacciones";
        break;
      default:
        subheader = "Nuevo monitoreo";
        break;
    }
    return subheader;
  };

  const childrenForm = [];

  if(userPrivileges.dashboard)
    childrenForm.push(
      {
        label: "Panel de control",
        tabContent: (
          <Grid container style={{
              display: "flex",
            }}>
            <MonitoringDashBoard />
          </Grid>
        ),
      });
  
  if(userPrivileges.error)
    childrenForm.push(
      {
        label: "Bitácora de errores",
        tabContent: (
          <Grid container style={{
            display: "flex"
          }}>
            <MonitoringView onlyContent={true} user={user}/>
          </Grid>
        ),
      });

  if(userPrivileges.transaction)
    childrenForm.push({
        label: "Detalle de transacciones",
        tabContent: (
          <Grid container style={{
            display: "flex"
          }}>
            <Grid
              item
              sm={4}
              xs={12}
              style={{ marginRight: "10px" }}
            >
              <ControlledInputRoundedForm
                id="objectType"
                name="objectType"
                label="Tipo de transacción"
                control={control}
                fullWidth
                select
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                {objectTypes.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    selected={option.selected}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </ControlledInputRoundedForm>
            </Grid>
            {objectTypes.filter(o=>o.id == watch("objectType")).map(objectType => (
              <CustomForms entityDefault={objectType.value} onlyContent={true} searchInit={objectType.filter} Loading={setLoading}/>
            ))
            }
          </Grid>
        ),
      });

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        isEditing={false}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        modalMessage={`¿Desea modificar esta configuración?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"tracings tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={childrenForm}
        />
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
TracingForm.propTypes = {
  
};

const mapStateToProps = (state) => ({
  transactionTypesResponse: state.objectTypesReducer.getTransactionTypesResponse
});

const mapDispatchToProps = {
  getTransactionTypesByEnterprise
};

export default connect(mapStateToProps, mapDispatchToProps)(TracingForm);
//#endregion