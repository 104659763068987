import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { actions } from "../utils/constants";
import {
  GET_USERS,
  GET_USER,
  GET_ERRORS,
  DEFAULTRESPONSE,
  GET_USER_APPLICATIONS,
  GET_USER_CHANGED,
  SET_USER_ERROR,
  SET_USER_DELETE,
  GET_USER_UPDATED,
  SET_USER_ACTIVATED,
  SET_CUSTOMERS_ERROR,
  SET_USER_EXISTS_RESPONSE,
  GET_USER_ROLES,
  GET_AUTHENTICATION_TYPES,
} from "./types";

export /**
 * User Action getUsers
 *
 * @method GET
 * @param {*}
 */
const getUsers = () => async (dispatch) => {
  try {
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/user/list",
      defaultHeaders()
    );
    dispatch({
      type: GET_USERS,
      payload: response.data.result.users,
    });
    //console.log(response.data.result.records);
  } catch (error) {
    console.error(error);
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getUserById
 *
 * @method GET
 * @param {*} userId
 */
const getUserById = (userId) => async (dispatch) => {
  if (userId === 0) {
    dispatch({
      type: GET_USER,
      payload: [],
    });
  } else {
    try {
      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        pid: userId.toLowerCase(),
      });
      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/user/GetById",
        myConfig
      );
      if (response.data.result.count === 0) {
        return;
      }
      dispatch({
        type: GET_USER,
        payload: response.data.result.records[0],
      });
    } catch (error) {
      const { response } = error;
      console.error(error);
      dispatch({
        type: GET_ERRORS,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  }
};

export /**
 * User Action inactivate ( inactivate  )
 *
 * @method POST
 * @param {*} userData
 */
const inactivate = (
  userData,
  updateUsersTable,
  history,
  isActivating
) => async (dispatch) => {
  await axios
    .post(
      API_SECURITY_ENDPOINT + "/api/user/Inactivate",
      userData,
      defaultHeaders()
    )
    .then((response) => {
      dispatch({
        type: GET_USER_UPDATED,
        payload: `El usuario quedó en estado ${
          userData.idstate === true ? "activo" : "inactivo"
        }`,
      });
      updateUsersTable();
    })
    .catch((error) => {
      console.error(error);
      const { response } = error;
      if (response) {
        dispatch({
          type: GET_ERRORS,
          payload: !!response
            ? response.data
            : DEFAULTRESPONSE.noResponseFromApi,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: DEFAULTRESPONSE.noResponseFromApi,
        });
      }
    });
};

export /**
 * User Action ActivateUser
 *
 * @method POST
 * @param {history} history
 */
const ActivateUser = (userData) => async (dispatch) => {
  try {
    var response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/activateDeletedUser",
      userData,
      defaultHeaders()
    );
    dispatch({
      type: SET_USER_ACTIVATED,
      payload: response.data.result,
    });
  } catch (err) {
    const response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: SET_USER_ACTIVATED,
      payload: response,
    });
  }
};

export /**
 * User Action getApplication
 *
 * @method GET
 * @param {*}
 */
const getApplications = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/application/list",
      defaultHeaders()
    );
    let myConfig = defaultHeaders();
    let applications = response.data.result.records;
    if (!!userId) {
      let rolInfo = [];
      Object.assign(myConfig.headers, {
        user: !!!userId ? "" : userId,
      });
      const {
        data,
      } = await axios.get(
        `${API_SECURITY_ENDPOINT}/api/application/GetCountRolesByUser`,
        { ...myConfig, user: userId }
      );
      rolInfo = data.result.records;
      applications = applications
        .filter((item) => item.isVisible && item.idState === 0)
        .map((app) => {
          let rolCount = rolInfo.filter((rol) => rol.appname === app.name)[0];
          return {
            ...app,
            rolCount: rolCount !== undefined ? rolCount.rolequantity : 0,
            rolCountUnique:
              rolCount !== undefined ? rolCount.rolequantityUnique : 0,
          };
        });
    } else {
      applications = applications
        .filter((item) => item.isVisible && item.idState === 0)
        .map((item) => ({ ...item, rolCount: 0, rolCountUnique: 0 }));
    }
    dispatch({
      type: GET_USER_APPLICATIONS,
      payload: applications,
    });
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getCustomers
 *
 * @method GET
 * @param {*}
 */
const getCustomers = (userId) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      userid: userId === 0 ? "" : userId,
    });
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/customer/GetCustomer",
      myConfig
    );

    if (response.data.result.customers.length > 0) {
      //Transformar los datos a como los espera la vista
      var customers = response.data.result.customers;
      customers.forEach((customer) => {
        if (customer.principal === true) {
          customer.assigned = false;
        }
      });
      dispatch({
        type: "GET_CUSTOMERS",
        payload: customers,
      });
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: SET_CUSTOMERS_ERROR,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export /**
 * User Action getRolesList
 *
 * @method GET
 * @param {*}
 */

const getRolesList = (appId, userId, appList, setLoading, setAppId) => async (
  dispatch
) => {
  try {
    let myConfig = defaultHeaders();
    let rolInfo = [];
    if (userId !== 0 && appId === "") {
      Object.assign(myConfig.headers, {
        user: !!!userId ? "" : userId,
      });
      const {
        data,
      } = await axios.get(
        `${API_SECURITY_ENDPOINT}/api/application/GetCountRolesByUser`,
        { ...myConfig, user: userId }
      );
      rolInfo = data.result.records;
      let firstApp = rolInfo.sort(
        (a, b) =>
          b.rolequantity +
          b.rolequantityUnique -
          (a.rolequantity + a.rolequantityUnique)
      )[0];
      appId = appList.filter((item) => item.name === firstApp.appname)[0].id;
    }
    if (userId === 0 && appId === "") {
      appId = 1;
    }

    Object.assign(myConfig.headers, {
      application: appId,
      user: !!!userId ? "" : userId,
    });
    setAppId(appId);
    const options = {
      method: "GET",
      url: API_SECURITY_ENDPOINT + "/api/role/list",
      ...myConfig,
      transformResponse: [
        (data) => {
          if (data !== "") {
            const jsonData = JSON.parse(data);
            if (jsonData.statusCode === 200) {
              const { records } = jsonData.result;
              const newRecords = records.filter((item) => item.idstate === 0);
              return {
                ...jsonData,
                result: {
                  ...jsonData.result,
                  records: newRecords,
                },
              };
            } else {
              return jsonData;
            }
          }
          return data;
        },
      ],
    };
    const response = await axios(options);
    if (response.data.result.count === 0) {
      return;
    }
    setLoading(false);
    let appRoles = response.data.result.records;
    dispatch({
      type: GET_USER_ROLES,
      payload: appRoles,
    });
  } catch (error) {
    const { response } = error;
    const { informationCode } = response.data.result;
    if (informationCode === "R11") {
      dispatch({
        type: GET_USER_ROLES,
        payload: [],
      });
      setLoading(false);
    }
  }
};

export /**
 * User Action createUser ( create/modify User  )
 *
 * @method POST
 * @param {history} history
 */

const createUser = (action, userData, restartFields, tab) => async (
  dispatch
) => {
  try {
    action =
      action === actions.CREATE
        ? "create"
        : action === actions.UPDATE
        ? "modify"
        : "inactivate";
    var response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/" + action,
      userData,
      defaultHeaders()
    );
    if (response.status === 200) {
      if (typeof restartFields === "function") {
        restartFields();
      }
      dispatch({
        type: GET_USER_CHANGED,
        payload:
          action === "create"
            ? "El usuario fue creado con éxito"
            : tab < 2
            ? " Los datos del usuario han sido actualizados"
            : "¡El usuario fue actualizado con éxito!",
      });
    } else {
      dispatch({
        type: GET_USER_CHANGED,
        payload: `El usuario fue creado con éxito`,
      });
    }
  } catch (err) {
    console.error(err);

    const response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: SET_USER_ERROR,
      payload: response,
    });
  }
};

export /**
 * User Action createUser ( create/modify User  )
 *
 * @method POST
 * @param {history} history
 */
const deleteUser = (userData) => async (dispatch) => {
  try {
    var response = await axios.post(
      API_SECURITY_ENDPOINT + "/api/user/delete",
      userData,
      defaultHeaders()
    );
    dispatch({
      type: SET_USER_DELETE,
      payload: response.data.result,
    });
  } catch (err) {
    const response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: SET_USER_DELETE,
      payload: response,
    });
  }
};

export /**
 * User Action GetUserExists
 *
 * @method GET
 * @param {history} history
 */
const getUserExists = (value) => async (dispatch) => {
  try {
    var response = await axios.post(
      `${API_SECURITY_ENDPOINT}/api/user/getuserexists`,
      value,
      defaultHeaders()
    );
    dispatch({
      type: SET_USER_EXISTS_RESPONSE,
      payload: response.data.result,
    });
  } catch (err) {
    const response = !!err.response
      ? err.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: SET_USER_ERROR,
      payload: response,
    });
  }
};

export /**
* User Action getAuthenticationTypes
*
* @method GET
*/
const getAuthenticationTypes = () => async (dispatch) => {
  try {
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/AuthenticationType",
      defaultHeaders()
    );    
    dispatch({
      type: GET_AUTHENTICATION_TYPES,
      payload: response.data.result.records,
    });
    
  } catch (error) {
    console.error(error);
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};
