import {
  SET_CURRENT_USER,
  SET_IS_AUTHENTICATED,
  SET_APP_NAME,
  SET_AUTH_STATUS,
  SET_USER_INFO,
  GENERATE_TOKENS,
  GET_ERRORS,
  REVOKE_TOKENS,
  GET_PRIVILEGES_AUTH,
  GET_MESSAGE_REFRESH_TOKEN,
  IS_REFRESH_TOKEN,
  AUTH_IS_FREE_TRIAL_APP,
  IS_INVALID_TOKEN,
  SEND_ACTIVATION_EMAIL,
  IS_LOGIN_AUTOMATIC,
  GET_RESPONSE_RESET_PASSWORD,
  GET_ERROR_RESET_PASSWORD
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  userInfo: undefined,
  appName: undefined,
  generateTokensResponse: {},
  generateTokensErrors: [],
  revokeTokenResponse: undefined,
  privilegesAuthResponse: {},
  MessageRefreshToken: {},
  isRefreshToken: false,
  authIsFreeTrialApp: { url: "", code: "" },
  isInvalidToken: {
    ok: false,
    message: ""
  },
  sendActivationEmail: {
    code: "",
    message: ""
  },
  isLoginAutomatic: false,
  //getAuthenticationResponse: false,
  //user: {},
  //respuesta: {},
  getErrorResetPassword: "",
  getResponseResetPassword: ""
};

/**
 * AuthReducer (set loggedIn user or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        getAuthenticationResponse: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_APP_NAME:
      return {
        ...state,
        appName: action.payload,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case GENERATE_TOKENS:
      return {
        ...state,
        generateTokensResponse: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        generateTokensErrors: action.payload,
      };
    case REVOKE_TOKENS:
      return {
        ...state,
        revokeTokenResponse: action.payload,
      };
    case GET_PRIVILEGES_AUTH:
      return {
        ...state,
        privilegesAuthResponse: action.payload,
      };
    case GET_MESSAGE_REFRESH_TOKEN:
      return {
        ...state,
        MessageRefreshToken: action.payload,
      };
    case IS_REFRESH_TOKEN:
      return {
        ...state,
        isRefreshToken: action.payload,
      };
    case AUTH_IS_FREE_TRIAL_APP:
      return {
        ...state,
        authIsFreeTrialApp: action.payload,
      };
    case IS_INVALID_TOKEN:
      return {
        ...state,
        isInvalidToken: action.payload,
      };
    case SEND_ACTIVATION_EMAIL:
      return {
        ...state,
        sendActivationEmail: action.payload,
      };
    case IS_LOGIN_AUTOMATIC:
      return {
        ...state,
        isLoginAutomatic: action.payload,
      };
    case GET_RESPONSE_RESET_PASSWORD:
      return {
        ...state,
        getResponseResetPassword: action.payload,
      };
    case GET_ERROR_RESET_PASSWORD:
      return {
        ...state,
        getErrorResetPassword: action.payload,
      };
    default:
      return state;
  }
}
