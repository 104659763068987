import React from 'react';

export const textToSpan = (text, classes) => {
    return (
        <span className={classes.titleTree}>{text}</span>
    )
}

export const jsonParse = (value, initialValue = {}, callback)=>{
    let obj = initialValue;
    try{
        obj = JSON.parse(value);
    } catch (ex){
        if(callback)
        callback(ex);
    }
    return obj ?? initialValue;
}

export const jsonStringify = (value, replacer, space, initialValue = "", callback)=>{
    let obj = initialValue;
    try{
        obj = JSON.stringify(value, replacer, space);
    } catch (ex){
        if(callback)
        callback(ex);
    }
    return obj ?? initialValue;
}